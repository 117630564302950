<template>
  <div class="text-decoration-none h-100">
    <div class="product-card row position-relative h-100 text-align-center">
      <h3 class="fw-bold product-title mb-4 mt-4">
        {{ bid.carId.name }} - {{ getColorDetail(bid.bidId) }} Color
      </h3>
      <div class="position-relative border-0">
        <image-slider
          class="product-image"
          :photos="
            bid && bid.images.length > 0 ? bid.images : [bid.bidId.carImage]
          "
          :id="bid._id"
          :bid="bid"
        ></image-slider>
      </div>
      <div class="my-auto mt-4">
        <div class="position-relative elbordaro">
          <h3 class="fw-bold product-title mb-3">
            Dealer's Bid
            {{ $formatPrice(bid.counterBid) }}
          </h3>
          <h3 class="product-title mb-2">
            Your Bid :
            {{ $formatPrice(bid.bidId.carBid) }}
          </h3>
          <p v-html="bid.carId.description"></p>
          <h5>
            <span v-html="bid.dealerComments"></span>
          </h5>
          <div class="details ps-0">
            <h6>
              Location : {{ bid.dealerId.city }}, {{ bid.dealerId.State }}
            </h6>
          </div>
        </div>
        <button
          type="button"
          class="btn btn-primary mb-4"
          @click="triggerAction()"
        >
          Accept
        </button>
      </div>
    </div>
  </div>
</template>
<script>
import ImageSlider from "../../atoms/Carousel/ImageSlider.vue";
import Swal from "sweetalert2";
import axios from "axios";
import { API_BASE_URL } from "../../../config";

export default {
  components: {
    ImageSlider,
  },
  props: {
    bid: {
      type: Object,
      required: true,
    },
    description: {
      type: String,
    },
  },
  methods: {
    getColorDetail(bid) {
      let variants = bid.variants.length > 0 ? bid.variants : [];
      let color = variants.filter(
        (variant) => variant.typeCategory === "color"
      )[0];
      if (color && color.typeSubCategory) {
        return color.typeSubCategory;
      } else {
        return "";
      }
    },
    async accpetBid() {
      try {
        this.$store.dispatch("setLoading", true);
        console.log(this.bid);
        await axios
          .post(
            `${API_BASE_URL}/product/acceptDealerBid/`,
            this.bid,
            this.headers
          )
          .then(async (res) => {
            console.log("res", res);
            this.$emit("getAllBids");
            Swal.fire("Saved!", `Bid Accepted Successfully`, "success");
          });
      } catch (error) {
        this.$store.dispatch("setLoading", false);
        throw error;
      }
    },
    triggerAction() {
      Swal.fire({
        title: "Do you want to accept the bid?",
        showDenyButton: true,
        confirmButtonText: "Yes",
        denyButtonText: `No`,
      }).then((result) => {
        /* Read more about isConfirmed, isDenied below */
        if (result.isConfirmed) {
          this.accpetBid();
        } else if (result.isDenied) {
          Swal.fire("Changes are not saved", "", "info");
        }
      });
    },
  },
};
</script>
<style scoped="scoped">
.product-card {
  background: white;
  width: 100%;
}
.card {
  width: 100%;
  overflow: hidden;
  margin: auto;
  display: flex;
  height: 300px !important;
  width: 300px;
  border-radius: 0 !important;
}
.product-title {
  background: #fff;
  line-height: 24px;
  color: #242424;
}
.prod-card-counter {
  transition: opacity 0.25s ease-in-out;
  top: -60px;
}
.product-card:hover .prod-card-counter {
  opacity: 0;
}
.product-title a:hover {
  color: var(--primary-color);
  transition: all 0.3s ease-out;
}
.product-title a {
  transition: all 250ms ease;
  -webkit-transition: all 250ms ease;
}
.product-card img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.product-card a {
  color: #484848;
  display: block;
  font-size: 18px;
  line-height: 22px;
  font-weight: bold;
  transition: color 0.25s ease-in-out;
}
.product-card a:hover {
  color: var(--primary-color);
}
.icon-card {
  left: 20px;
  z-index: 1;
}
.details {
  overflow: hidden;
  padding: 5px 5px 10px;
  background: #fff;
  color: #606060;
}
.show,
.hide {
  transition: all 0.25s ease-in-out;
}
.show,
.product-card:hover .hide {
  visibility: hidden;
  opacity: 0;
}
.product-card:hover .show {
  visibility: visible;
  opacity: 1;
  transform: scale(1.1);
}

.product-card {
  overflow: hidden;
  box-shadow: 0 0 25px rgb(0 0 0 / 10%);
}
@media (max-width: 767.98px) {
  .prod-card-counter {
    top: -55px;
  }
  .Counter {
    margin-top: 25px;
    width: 100%;
    display: flex;
  }
  .product-title a {
    font-size: 15px !important;
  }
  .product-title {
    padding-top: 0 !important;
    padding: 20px 5px 0;
  }
  .details {
    font-size: 12px !important;
  }
  .elbordaro {
    margin-top: 10px;
  }
}
.text-align-center {
  text-align: center;
}
</style>
