<template>
  <layout-default>
    <!-- <div class="container-fluid  slider p-0">
      <JumbotronFluid
        title="Find your dream car"
        subtitle="& give it a try"
        content="Vivamus id ligula non turpis aliquam dignissim. Fusce tempor vulputate
        urna, quis malesuada."
        :imagePath="'images/slider/NotSticker_automotive_slider.jpg'"
      />
    </div> -->

    <!-- <div class="container latest-auctions" style="margin-top:100px;">
      <SectionLatestAuctions />
    </div> -->
    <div class="container masonry mt-1em">
      <div class="row d-flex" v-if="cars.length > 0">
        <div class="col-md-6" v-for="(make, i) in cars" :key="i">
          <MasonryProduct
            :title="make.name"
            :height="400"
            :productsNo="make.cars.length"
            :imageSrc="make.imageUrl"
            :viewMoreLocation="'/shop/' + make._id"
            :productTitle="make.cars.length === 1 ? 'car' : 'cars'"
          />
        </div>
      </div>
    </div>
    <div
      class="container latest-auctions-category"
      v-for="(make, i) in cars"
      :key="i"
    >
      <SectionLatestAuctionsWithCategoryName
        v-if="make.cars.length"
        :title="make.name"
        :productTitle="make.cars.length === 1 ? 'car' : 'cars'"
        :productsNo="make.cars.length"
        :details="make"
        :background="'url(' + make.imageUrl + ') no-repeat center center;'"
      />
    </div>

    <div class="container contact">
      <SectionContact />
    </div>

    <!-- <div class="container news justify-content-center">
      <SectionNews />
    </div> -->
    <SectionBrands class="d-none fluid-container mb-0 brands d-md-block" />
  </layout-default>
</template>

<script>
// @ is an alias to /src

import SectionLatestAuctions from "../../components/organisms/Section/SectionLatestAuctions.vue";
import SectionLatestAuctionsWithCategoryName from "../../components/organisms/Section/SectionLatestAuctionsWithCategoryName.vue";
import SectionContact from "../../components/organisms/Section/Contact/SectionContact1.vue";
import SectionNews from "../../components/organisms/Section/SectionNews.vue";
import SectionBrands from "../../components/organisms/Section/SectionBrands.vue";
import JumbotronFluid from "../../components/molecules/Jumbotron/JumbotronFluid.vue";
import LayoutDefault from "../../components/organisms/LayoutDefault/LayoutDefault.vue";
import MasonryProduct from "../../components/molecules/Banner/BannerMasonry.vue";
import { API_BASE_URL } from '../../config';

import { mapGetters } from "vuex";
import axios from "axios";
import Swal from "sweetalert2";
import _ from "lodash";

export default {
  name: "Home",
  computed: {
    ...mapGetters([
      'appLoading',
    ])
  },
  data() {
    return {
      headers: JSON.parse(localStorage.getItem("headers")),
      url: API_BASE_URL,
      makers: [],
      cars: [],
    };
  },
  beforeCreate() {
    document.title = "NotSticker - Buy Your New Car Now";
  },
  mounted() {
    this.headers = JSON.parse(localStorage.getItem("headers"));
    this.$store.dispatch('setLoading', true)
    this.getMakers();
  },
  components: {
    SectionLatestAuctions,
    SectionLatestAuctionsWithCategoryName,
    SectionContact,
    SectionNews,
    SectionBrands,
    JumbotronFluid,
    LayoutDefault,
    MasonryProduct,
  },
  methods: {
    async getMakers() {
      this.$store.dispatch('setLoading', true)
      try {
        this.$store.dispatch('setLoading', true)
        await axios
          .get(`${this.url}/product/allCars`, this.headers)
          .then(async (res) => {
            this.cars = res.data.data;
            this.$store.dispatch('setLoading', false)
          });
      } catch (error) {
        this.$store.dispatch('setLoading', false)
        throw error;
      }
    },
    async getCarByMakerId(payload, index) {
      try {
        this.$store.dispatch('setLoading', true)
        let id = payload._id;
        console.log(id);
        await axios
          .get(`${this.url}/product/getCarsByCompanyId/${id}`, this.headers)
          .then((res) => {
            let data = {
              ...payload,
              cars: [...res.data.data],
              time: new Date().getTime(),
            };
            this.$store.dispatch('setLoading', false)
            this.cars[index] = data;
          });
      } catch (error) {
        this.$store.dispatch('setLoading', false)
      }
    },
  },
};
</script>
<style scoped="scoped">
.features,
.latest-auctions,
.latest-auctions-category,
.masonry,
.contact,
.news,
.brands {
  margin-bottom: 90px;
}
.latest-auctions-category {
  padding: 0 21px;
}
.brands {
  background-color: #f9f9f9;
  padding: 70px 0px;
}
:deep(.Counter .col-3) {
  padding: 8px 13px !important;
}
@media (max-width: 767.98px) {
  :deep(.latest-auctions-category) {
    padding: 0px !important;
  }
  :deep(.mas) {
    height: 100% !important;
  }

  .brands {
    padding: 70px 0px;
  }
  :deep(.Counter .col-3) {
    padding: 7px 0px !important;
  }
}
.mt-1em {
  margin-top: 1em;
}
</style>
