<template>
  <layout-default>
    <div class="container shop-breadcrumb border-bottom">
      <Breadcrumb class="breadcrumb" />
      <h1 class="shop-title mt-2">All Bids</h1>
    </div>
    <div class="shop-container row">
      <div v-if="bids.length > 0">
        <div v-for="(bid, i) in bids" :key="i" class="col-md-6 col-lg-4 col-sm-12 position-relative mb-2">
          <bid-details-card
            v-bind="bid"
            :bid="bid"
            @getAllBids="dealerBidByUser()"
          ></bid-details-card>
        </div>
      </div>
      <div v-else class="container justify-content-center">
        <h2>No bids found.</h2>
      </div>
    </div>
  </layout-default>
</template>

<script>
import LayoutDefault from "../../components/organisms/LayoutDefault/LayoutDefault.vue";
import PaginationButtons from "../../components/atoms/PaginationButtons/PaginationButtons.vue";
import FilterListCategoryAndSubcategories from "../../components/atoms/Filter/FilterListCategoryAndSubcategories.vue";
import FilterListTags from "../../components/atoms/Filter/FilterListTags.vue";
import FilterListSize from "../../components/atoms/Filter/FilterListSize.vue";
import FilterBar from "../../components/atoms/Filter/FilterBar.vue";
import Grid from "../../components/molecules/Layout/Grid.vue";
import List from "../../components/molecules/Layout/List.vue";
import BasicSelectBox from "../../components/atoms/FormInput/BasicSelectBox.vue";
import ChangeLayoutButtons from "../../components/atoms/Button/ButtonDouble.vue";
import BasicInputText from "../../components/atoms/FormInput/BasicInputText.vue";
import Breadcrumb from "../../components/atoms/Breadcrumb/Breadcrumb.vue";
import BasicInputSearch from "../../components/atoms/FormInput/BasicInputSearch.vue";
import FilterTitle from "../../components/atoms/Filter/FilterTitle.vue";
import ButtonFilled from "../../components/atoms/Button/ButtonFilled.vue";
import VueSlider from "vue-slider-component";
import "vue-slider-component/theme/default.css";
import { products } from "../../data/auctions.json";
import { tags } from "../../data/tags.json";
import { categories } from "../../data/productCategories.json";
import { dimensions } from "../../data/dimensions.json";
import axios from "axios";
import { API_BASE_URL } from '../../config';
import ProductSlider from "../../components/atoms/Carousel/ProductSlider.vue";
import BidDetailsCard from "../../components/molecules/Product/BidDetailsCard.vue";

import { mapGetters } from "vuex";
export default {
  beforeCreate() {
    document.title = "Products - NotSticker";
  },
  mounted() {
    this.$store.commit("setLocation", [{ title: "All Bids", location: "/allbids" }]);
    this.dealerBidByUser();
  },
  components: {
    ProductSlider,
    BidDetailsCard,
    LayoutDefault,
    PaginationButtons,
    FilterListCategoryAndSubcategories,
    FilterListTags,
    FilterListSize,
    FilterBar,
    Grid,
    List,
    BasicSelectBox,
    ChangeLayoutButtons,
    VueSlider,
    Breadcrumb,
    FilterTitle,
    BasicInputSearch,
    ButtonFilled,
    BasicInputText,
  },
  data() {
    return {
      headers: JSON.parse(localStorage.getItem("headers")),
      url: API_BASE_URL,
      bids: [],
    };
  },
  methods: {
    async dealerBidByUser() {
      try {
        this.$store.dispatch('setLoading', true)
        await axios
          .get(
            `${this.url}/product/dealerBidByUser/${this.user._id}`,
            this.headers
          )
          .then(async (res) => {
            console.log("res",res);
            this.bids = res.data.data;
            this.$store.dispatch('setLoading', false)
          });
      } catch (error) {
        this.$store.dispatch('setLoading', false)
        throw error;
      }
    },
  },
  computed: {
    ...mapGetters(["user"]),
  },
};
</script>
<style>
.vue-slider-process,
.vue-slider-dot-tooltip-inner {
  background-color: var(--primary-color);
  border-color: var(--primary-color);
}
</style>
<style scoped="scoped">
.sorting {
  border: 1px solid #e6e6e6 !important;
  border-radius: 30px;
  padding: 0px 10px;
}
.btn-link {
  color: var(--primary-color);
  background: none !important;
}
.title {
  margin-top: 25px;
  font-size: 22px !important;
  width: 80%;
}
.shop-title {
  font-weight: 600 !important;
}
.filter-col {
  padding: 0px 15px;
  order: 1;
}
.list-search {
  border: 1px solid #e6e6e6 !important;
}
.input-color,
.input-condition,
.search-field.radius.border.input-condition {
  border-radius: 5px !important;
  border-color: #aaa;
  transition: border-color 0.25s ease-in-out;
}
.shop-grid {
  order: 2;
}
.shop-container {
  padding-top: 50px;
  padding-bottom: 50px;
  margin: 1em;
  margin-right: 0;
}
.breadcrumb {
  padding-top: 40px;
}
.shop-breadcrumb {
  padding-bottom: 40px;
}
ul {
  padding: 0px;
}
.Minicart_button {
  width: 100px !important;
}
.filter-btn {
  margin-top: 5px;
  padding: 7px 25px !important;
}
.shop-slider {
  width: 240px;
}
.pagination-shop {
  margin-bottom: 70px;
  margin-top: 70px;
}
.price-font {
  font-size: 0.8751em;
}
.filter-search-shop,
.color-select-shop,
.condition-select-shop,
.filter-range-shop,
.size-select-shop,
.tags-shop,
.categories-shop {
  margin-bottom: 15px;
}
.color-filter,
.condition-filter {
  border-radius: 15px !important;
}

:deep(.Counter .col-3) {
  padding: 6px 9px !important;
}
@media (max-width: 767.98px) {
  :deep(.left-side) {
    width: 100%;
  }

  .filter-mobile {
    display: block !important;
    position: fixed;
    height: 100%;
    left: 0;
    top: 0;
    width: fit-content;
    z-index: 9999;
    background: white;
    padding-top: 50px !important;
    overflow: auto;
    transform: translateX(-100%);
    transition: transform 0.25s ease-in-out;
  }
  .filter-mobile.active {
    transform: translateX(0);
  }
  .pagination-shop {
    justify-content: flex-start !important;
    padding: 0 10px !important;
  }
  .filter-bar-shop {
    padding: 0 10px;
  }
  .shop-breadcrumb {
    margin-bottom: 20px;
  }
  .breadcrumb {
    padding-top: 10px;
    margin-bottom: 0;
  }
  .filter-col {
    padding: 0 0 0 24px;
    order: 2;
  }
  .shop-grid {
    order: 1;
  }
  .shop-container {
    padding: 0;
  }
  .shop-breadcrumb {
    padding: 5px 10px;
  }
  :deep(.Counter .col-3) {
    padding: 7px 0px !important;
  }
}

@media (min-width: 768px) and (max-width: 1024px) {
  .shop-slider {
    width: 160px !important;
  }
}
.all-bids-container {
  height: 10vh;
}
.bid-image {
      width: 20vw;
    height: 30vh;
    object-fit: cover;
}
.mb-2 {
  margin-bottom: 2em !important;
}
</style>
