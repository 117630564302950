<template>
  <div>
    <br />
    <div :id="id" class="carousel slide" data-ride="carousel">
      <!-- Indicators -->
      <ol class="carousel-indicators">
        <li style="display: none" :data-target="`#${id}`" v-for="(image, i) in photos" :key="image" :data-slide-to="i" :class="{'active': i === 0}"></li>
      </ol>

      <!-- Wrapper for slides -->
      <div class="carousel-inner" role="listbox">
        <div v-for="(image, i) in photos" :key="image" class="item" :class="{'active': i === 0}">
            <a :href="image" target="_blank">        
                <img
                    :src="image"
                    :alt="image"
                    style="height: 400px; width: 400px; object-fit: cover;"
                />
            </a>
        </div>
<!-- 
        <div class="item">
          <img
            src="https://alkite.files.wordpress.com/2009/05/surfing-1.jpg"
            alt="Chania"
            width="460"
            height="345"
          />
        </div> -->
      </div>

      <!-- Left and right controls -->
      <a
        class="left carousel-control"
        :href="`#${id}`"
        role="button"
        data-slide="prev"
      >
        <span
          class="glyphicon glyphicon-chevron-left"
          aria-hidden="true"
        ></span>
        <span class="sr-only">Previous</span>
      </a>
      <a
        class="right carousel-control"
        :href="`#${id}`"
        role="button"
        data-slide="next"
      >
        <span
          class="glyphicon glyphicon-chevron-right"
          aria-hidden="true"
        ></span>
        <span class="sr-only">Next</span>
      </a>
    </div>
  </div>
</template>
<script>
// Import Swiper Vue.js components
import { Swiper, SwiperSlide } from "swiper/vue";

import "swiper/swiper-bundle.css";

// import Swiper core and required modules
import SwiperCore, { Navigation, Thumbs } from "swiper";

// install Swiper modules
SwiperCore.use([Navigation, Thumbs]);

export default {
  components: {
    Swiper,
    SwiperSlide,
  },
  props: {
    photos: {
      type: Array,
    },
    id: {
      type: String,
    },
    bid: {
        type: Object
    }
  },
  data() {
    return {
      thumbsSwiper: null,
    };
  },
  methods: {
    setThumbsSwiper(swiper) {
      this.thumbsSwiper = swiper;
    },
  },
};
</script>
<style scoped>
.carousel-inner > .item > img,
.carousel-inner > .item > a > img {
  width: 100%;
  margin: auto;
}
.container {
  width: 350px !important;
}
</style>